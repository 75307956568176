<template>
  <div class="container terms-conditions">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <h2>{{ termsdata.title }}</h2>
        <div class="desc" v-html="termsdata.description"></div>
        <!--        <img :src="'http://43.205.178.155/euadmin/'+termsdata.image" alt="">-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axios.js'

export default {
  data() {
    return {
      termsdata: []
    }
  },
  metaInfo() {
    return {
      title: this.termsdata.title,
      titleTemplate: "%s | Eurasia",
      meta: [
        {
          name: "description",
          content: this.termsdata.meta_description,
        },
        {
          name: "keyword",
          content: this.termsdata.meta_keyword,
        },
      ],
    };
  },
  watch: {
    $route() {
      axios.get(`${this.$route.params.slug}`).then(res => {
        this.termsdata = res.data.data;
      })
    }
  },
  mounted() {
    axios.get(`${this.$route.params.slug}`).then(res => {
      this.termsdata = res.data.data;
      // console.log("Term and con ======", res)
    })
  }
}
</script>
<style>
.terms-conditions {
  text-align: left;
  font-size: 16px;
  margin: 20px 0px;
}

.terms-conditions h2 {
  text-align: center;
  color: red;
  font-size: 40px;
}

.terms-conditions p {
  font-size: 16px;
  font-weight: bold;
}

.terms-conditions ul li {
  margin-bottom: 15px;
  font-weight: 500;
}
</style>